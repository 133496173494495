import { captureException } from '@sentry/nextjs';
import { NextRouter } from 'next/router';
import messages from './messages';

export function getFormattedMessages(intl, { basePrice, currency, hasFreeReturns, hasHTO, htoLimit }) {
  const price = intl.formatNumber(basePrice, {
    currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency'
  });
  const title = intl.formatMessage(messages.title, {
    basePrice: price
  });

  const description = [
    intl.formatMessage(messages.descriptionBase, {
      basePrice: price
    })
  ];
  if (hasFreeReturns) {
    description.push(intl.formatMessage(messages.descriptionFreeShippingReturns));
  }
  if (hasHTO) {
    description.push(
      intl.formatMessage(messages.descriptionHTO, {
        count: htoLimit
      })
    );
  }

  return {
    basePrice: price,
    description: description.join(' '),
    title
  };
}

export function getDefaultCanonicalUrl(router: NextRouter, locale = router.locale) {
  try {
    const basePath = 'https://www.aceandtate.com/';
    const fullPath = basePath + locale + router.asPath;
    const url = new URL(fullPath);
    return (url.origin + url.pathname).replace(/^\/|\/$/g, '');
  } catch (e) {
    captureException(e);
  }
}
